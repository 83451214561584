import React from 'react'
import { useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import * as colors from '../components/colors'
import * as typography from '../components/typography'

const StyledAbout = styled.div`
  display: grid;
  grid-template-columns:
    minmax(1.2rem, 1fr)
    minmax(0, 65ch)
    minmax(1.2rem, 1fr);  
  margin: 0 80px 24px 80px;

  @media (max-width: 960px) {
    margin-left: 16px;
    margin-right: 16px;
  }

  h1 {
    ${typography.heading3};
    grid-column: 1 / 4;
  }

  p {
    grid-column: 1 / 4;
    ${typography.baseUI()}
    font-size: 22px !important;
    margin: 12px 0;
    font-size: 20px;
    color: ${colors.N750};
  }
`

const About = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  // console.log(data)
  // const siteData = data.site.siteMetadata
  // const siteTitle = siteData.title
  // const siteDescription = siteData.description

  return (
    <Layout>
      <SEO title="About" />
      <Container>
        <StyledAbout>
          <h1>About</h1>
          <p>
            The war against bad design
          </p>
        </StyledAbout>
      </Container>
    </Layout>
  )
}

export default About
